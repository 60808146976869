import createReactComponent from '../createReactComponent';

const Move = createReactComponent('Move', [
  ['polyline', { points: '5 9 2 12 5 15', key: '1r5uj5' }],
  ['polyline', { points: '9 5 12 2 15 5', key: '5v383o' }],
  ['polyline', { points: '15 19 12 22 9 19', key: 'g7qi8m' }],
  ['polyline', { points: '19 9 22 12 19 15', key: 'tpp73q' }],
  ['line', { x1: '2', y1: '12', x2: '22', y2: '12', key: 'zvmn4p' }],
  ['line', { x1: '12', y1: '2', x2: '12', y2: '22', key: '1k6o5o' }],
]);

export default Move;
